import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import {FiMapPin, FiPhone, FiMail} from 'react-icons/fi';

import {AiFillInstagram, AiOutlineTwitter} from 'react-icons/ai';

const ContactStyle = styled.div`
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10vh 5vw 6vh;
    .hours{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: var(--pink);
        font-size: 31px;
        padding-top: 20px;
        padding-bottom: 20px;
        h3{
            font-family: Roboto;
        }
        
    }
    .address{
        display: flex;
        color: var(--pink);
        padding: 20px 0px;
        a{
            color: white;
            padding-left: 12px;
        }
    }
    .email{
        display: flex;
        color: var(--pink);
        padding: 20px 0px;
        a{
            color: white;
            padding-left: 12px;
        }
    }
    .phone{
        display: flex;
        color: var(--pink);
        padding: 20px 0px;
        a{
            color: white;
            padding-left: 12px;
        }
    }

    .socials{
        display: flex;
        color: var(--pink);
        font-size: 36px;
        .social{
            margin: 10px 10px;
            &:hover{
                opacity: 0.6;
                cursor: pointer;
            }
        }
        
    }
`;

export default function ContactUs({hourstext, address, email, phone, comingsoon}){
    return(
        <ContactStyle>
            <h2>Contacts</h2>
            <div className='hours'>
                {
                    (comingsoon) ?
                    <h3>COMING SOON</h3>
                    :
                    <></>
                }
                {
                    hourstext.map((text) => (
                        <h3>{text}</h3>
                    ))
                }
            </div>
            
            <div className="address">
                <FiMapPin/><a href="/">{address}</a>
            </div>
            <div className="email">
                <FiMail/><a href={"mailto:" + email}>{email}</a>
            </div>
            <div className="phone">
                <FiPhone/><a href={"tel:" + phone}>{phone}</a>
            </div>
            <div className="socials">
                <a href="https://twitter.com/RoninCannabis" target="_blank"><div className="social" ><AiFillInstagram /></div></a>
                <a href="https://www.instagram.com/ronin_cannabis/" target="_blank"><div className="social" ><AiOutlineTwitter/></div></a>
            </div>
        </ContactStyle>
    );
}