import React from 'react';
import styled from 'styled-components';

import { graphql, useStaticQuery, Link } from 'gatsby'
import { getImage } from "gatsby-plugin-image"

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

// const HomeHeroStyle = styled.div`
//     background-image: url('./ronin-bg.jpg');
//     height: 100vh;
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-position: center;
// `;

const Wrapper = styled.div`
    width: 100%;
    .banner-image,
    .banner-image::before,
    .banner-image::after{
        background-position: center center;
        min-height: 100vh;
        
    }
    @media (max-width: 500px){
        .banner-image,
        .banner-image::before,
        .banner-image::after{
            height: 112vh;
        }
    }

    .stroke{
        position: relative;
        z-index: 5000;
        margin-top: -5vh;
        margin-bottom: -10vh;
        width: 100%;
        @media (max-width: 1000px){
            display: none;
        }
    }
`;

const TextSection = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15vh 50px;
  padding-top: 20vh;

    h1{
        font-family: Space-Age;
    }

    h3{
        color: white;
        font-size: 40px;
        max-width: 800px;
        padding: 20px 0px;
        @media screen and (max-width: 900px){
            font-size: 20px;
        }
    }

    h4{
        font-family: Space-Age;
        color: var(--pink);
        padding-bottom: 4vh;
    }

    p{
        margin-top: 5vh;
        color: white;
        max-width: 80%;
        font-size: 18px;
        @media screen and (max-width: 900px){
            font-size: 17px;
            margin-top: 1vh;
            max-width: 100%;
            padding: 0px 10px;
        }
        margin: 5px;
    }

  button {
    margin-top: 3.5vh;
    padding: 10px 60px;
  }

  @media screen and (max-width: 900px) {
    text-align: center;
    width: 100%;
    align-items: center;
    padding: 11vh 20px;
    padding-top: 18vh;
  }
`;

export default function HomePageHero({subtitle, title, subtitleB, content, buttontext, bannerImage, comingSoon, location}){
    // ----Gatsby Background Image Set-Up-----
    
    const image = getImage(bannerImage)
    const bgImage = convertToBgImage(image)
    
    return(
        <Wrapper>
            <BackgroundImage
                Tag="div"
                {...bgImage}
                preserveStackingContext
                className="banner-image"
                style={{
                    width: '100%',
                    height: '',
                    backgroundPosition: ''
                }}
            >
                <TextSection>
                    <h4>{subtitle}</h4>
                    {comingSoon ? <h1> </h1> : <h1>{title}</h1>}
                    {comingSoon ? <h1>OPENING SOON</h1> : <h2> </h2>}
                    <h3>{subtitleB}</h3>
                        {content.map((block) => (
                            <p>{block}</p>
                        ))}                    
                    {
                        comingSoon ?
                        <button>{buttontext}</button>
                        :
                        <Link to={location ? `/${location}/livemenu` : '/locations'}>
                            <button>{buttontext}</button>
                        </Link>
                    }
                </TextSection>
            </BackgroundImage>
            <img src="/svg/BrushStroke.svg" class="stroke" />
        </Wrapper>
    )
}
