import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination} from 'swiper';
import {BsStarFill, BsStarHalf} from 'react-icons/bs';

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

const ReviewSlide = styled.div`
    height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: black;
    padding-bottom: 75px;
    color: white;
    text-align: center;

    .stars{
        padding-top: 10vh;
        color: var(--pink);
    }

    .fotnote{
        display: flex;
        justify-content: center;
        .location{
            opacity: 0.7;
            padding-left: 10px;
        }
    }

    p{
        max-width: 1000px;
    }
`;

const ReviewStyles = styled.div`
    .swiper-button-next,
    .swiper-button-prev{
            color: white !important;
    }
    .swiper-pagination-bullet-active{
        background: white !important; 
    }
    .swiper-pagination-bullet{
        background: gray !important;
    }
    .stroke{
        width: 100%;
        margin-top: -60px;
        @media (max-width: 1000px){
            display: none;
        }
    }
    background-color: black;
`;

function ReviewStars({stars}) {
    const starsArr = [];
    for (var i=0; i < Math.floor(stars); i++) {
        starsArr.push(<BsStarFill/>);
    }
    if (Math.floor(stars) != stars){
        starsArr.push(<BsStarHalf/>)
    }
    return(
        <div className='stars'>
            {starsArr}
        </div>
    )
}

export default function Review({reviewdata}){
    return(
        <ReviewStyles>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                    onSwiper={(swiper) => console.log(swiper)}
                    >
                {reviewdata.nodes.map((review) => (
                    
                    <SwiperSlide>
                        <ReviewSlide>
                
                            <h2>{review.title}</h2>
                            <ReviewStars stars={review.stars} />
                            <p>{review.review}</p>
                            <div className="fotnote">
                                <div className="author"><h5>{review.customer}</h5></div>
                                <div className="location"><h5>{review.location}</h5></div>
                            </div>

                        </ReviewSlide>
                    </SwiperSlide>
                ))}
                </Swiper>
        </ReviewStyles>
    );
}
